[data-component="ConsolePage"] {
  font-size: 16px;
  height: 100%; // Use full viewport height
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  max-width: 100vw;
  // background-color: #f3f4f6; // Light gray background
  .conversation-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  .conversation-container {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-height: 40px;
    .content-title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .content-main {
    flex-grow: 1;
    flex-shrink: 1 !important;
    margin: 0px 16px;
    display: flex;
    margin-bottom: 24px; max-width: 100vw;
    .content-block {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      width: 100%;
      overflow-x: scroll; max-width: 90vw;
      .content-block-title {
        flex-shrink: 0;
        padding-top: 16px;
        padding-bottom: 4px;
        position: relative;
      }
      .content-block-body {
        color: #6e6e7f;
        position: relative;
        flex-grow: 1;
        padding: 8px 0px;
        padding-top: 4px;
        line-height: 1.2em;
        // overflow: auto;
        &.full {
          padding: 0px;
        }
      }
    }
    .content-right {
      width: 300px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      gap: 24px;
      & > div {
        border-radius: 16px;
        flex-grow: 1;
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
        .content-block-title {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 2em;
          top: 16px;
          left: 16px;
          padding: 4px 16px;
          background-color: #fff;
          border-radius: 1000px;
          min-height: 32px;
          z-index: 9999;
          text-align: center;
          white-space: pre;
          &.bottom {
            top: auto;
            bottom: 16px;
            right: 16px;
          }
        }
      }
      & > div.kv {
        height: 250px;
        max-height: 250px;
        white-space: pre;
        background-color: #ececf1;
        .content-block-body {
          padding: 16px;
          margin-top: 56px;
        }
      }
    }
    .content-logs {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      & > div {
        flex-grow: 1;
      }
      & > .content-actions {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
      }
      & > div.events {
        overflow: hidden;
      }
      .events {
        border-top: 1px solid #e7e7e7;
      }
      .conversation {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        // overflow: hidden;
        overflow: scroll;
        height: 50vh;
        border-top: 1px solid #e7e7e7;
      }
      .older-messages {
        flex: 0 0 33.333%;
        overflow-y: auto;
        padding: 0.5rem;
        font-size: 1rem;
        background-color: #f9f9f9; // White background
        height: 33.333vh; // Set height to one-third of viewport height on mobile

        @media (min-width: 768px) {
          height: 100%; // Reset to full height on larger screens
        }
      }

      .conversation-item {
        margin-bottom: 0.25rem;
        padding: 0.25rem;
        border-radius: 0.25rem;

        &.user {
          background-color: #f9f9f9; // Light blue background
          color: #0f0f0f; // Dark blue text
          text-align: right;
        }

        &.assistant {
          background-color: #e5e7eb; // Light gray background
          color: #1f2937; // Dark gray text
          text-align: left;
        }

        .speaker-content {
          font-size: 1rem;

          display: inline-block;
          padding: 0.5rem;
          border-radius: 0.5rem;
        }

        .audio-player {
          width: 100%;
          height: 15px;
          margin-top: 0.25rem;
        }
      }
      .latest-message-container {
        flex: 0 0 66.666%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        overflow: hidden;
        background-color: #ffffff; // White background
        height: 66.666vh; // Set height to two-thirds of viewport height on mobile

        @media (min-width: 768px) {
          height: 100%; // Reset to full height on larger screens
        }
      }

      .latest-message {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        color: #000;
        max-width: 90%;
        line-height: 1.4;

        @media (min-width: 768px) {
          font-size: 3rem;
        }

        @media (min-width: 1024px) {
          font-size: 4rem;
        }
      }

      .controls-container {
        position: relative; // Change from fixed to relative
        flex-shrink: 0; // Prevent shrinking
        height: 80px;
        width: 100%; // Take full width of its parent
        padding: 1rem;
        background-color: rgba(
          128,
          128,
          128,
          0.5
        ); // Change to gray and transparent background
        z-index: 10;
      }

      // Remove the content-wrapper class as it's not needed
    }
  }
}
